import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import Loader from "../../utilities/Loader";
import configData from "../../vars.json";
const redirectURL = configData.redirectUrl ;




const TwoFactorAuth = ({ baseURL3, baseURL, baseURL2 }) => {


  const headers = {
    Authorization: "Hola Mundo",
  };


  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();


  React.useEffect(() => {
    const token = query.get('id')
    const token2 = query.get('rid')


    const typeSelected  = query.get('typeSelected')
    const countrySelected = query.get('countrySelected')
    const ver_id  = query.get('ver_id')
    const status = query.get('status')
    const workflow_id  = query.get('workflow_id')
    const  applicant_id = query.get('applicant_id')


    console.log("THESE IS THE TOKEN: ")
    console.log(token)
    console.log("THESE ARE THE HEADERS OF THE WEB APP:")
    console.log(headers);
    console.log(JSON.stringify({ id: token, rid: token2 }))
    localStorage.setItem('headers', JSON.stringify({ id: token, rid: token2, typeSelected:typeSelected, countrySelected:countrySelected, 
    ver_id:ver_id, status:status, workflow_id:workflow_id, applicant_id:applicant_id }))
    const timeout = setTimeout(() => {
      window.location.replace(redirectURL)
    }, 50);
    return () => clearTimeout(timeout);
  }, []);


  return (
    <div className="pt-5 px-3 lg:px-0 lg:pt-[102px]">
      <Loader></Loader>
    </div>
  );
};

export default TwoFactorAuth;
