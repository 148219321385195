import "./main.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import playicons from "./playicon.svg";
import pauseicon from "./pause.svg";
import fullscreenicon from "./fullscreenIcon.svg";
import Loader from "../../utilities/Loader";
import configData from "../../vars.json";
import Button1 from "../../shared/Buttons/Button1";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { updateSampleSection } from "../sample-base";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { MyContext } from "../../utilities/ContextData";
const baseURL = configData.baseUrl ;

const Mainshow = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [resultado, setResultado] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSign, setIsSign]=useState(true)
  const { main, setMain, setMyHome } = useContext(MyContext);
  const videoRef = useRef(null);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };


  const getdoc = async() =>{

/*
  await fetch("https://s3.amazonaws.com/pastpost.xherstone.com/Please_sign_this_document.pdf").then((resi) => {
    resi.blob().then((blob) => {
  var file = new File([blob], "nombre.pdf", {
        type: "application/pdf",
      });
      getBase64(file)
      .then((result) => {
        setResultado(result)
        setIsSign(false)
      });        
    });
    return true; 
  })*/

  await axios.post(baseURL+"pdf/signer/document", {envelope:JSON.parse(localStorage.getItem('signedpdf')).envelope},{  headers: {
    // 'application/json' is the modern content-type for JSON, but some
    // older servers may use 'text/json'.
    // See: http://bit.ly/text-json
    "content-type": 'text/json', 
    "Authorization": "Bearer "+JSON.parse(localStorage.getItem('headers')).id
 
    
  }}).then((result) => {
    console.log("DATA BASE 64")
    console.log(result.data.fileBytes)
    setResultado(result.data.fileBytes)
    setIsSign(false)
    return true;
  })



  }
  

  function redir() {
        window.location.replace('com.pastpost://');
    
  }

  useEffect(async() => {
    setMyHome(false);
    getdoc();
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);


   
    
   
    
  }, []);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();



  const [numPages, setNumPages] = React.useState(null);
  const headers = {
    Authorization: "Hola Mundo",
  };

  // const [show, setShow] = React.useState(false)-no-;
  // const [mediaType, setmediaType] = React.useState(location.state.mediaType)-no-;

  const location = useLocation();

  
  //reloading problem solved here......................................
  const navigate = useNavigate();

  if (isLoading) {
    return <Loader />;
  }
  return (
    <section className="grid grid-cols-1 bg-white">
     {isSign && <Loader />}
     {!isSign &&<div className="pdfCont">
          <PdfViewerComponent
            id="container"
            documentPath={resultado}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
            style={{ height: "80vh" }}
          >
            <Inject
              services={[
                Toolbar,
                Magnification,
                Navigation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                Print,
                TextSelection,
                TextSearch,
                Annotation,
                FormFields,
                FormDesigner,
              ]}
            />
          </PdfViewerComponent>
          <Button1 handleclick={redir}>Cierra esta ventana para volver a la app</Button1>
        </div>}
    </section>
  );
};

export default Mainshow;

  /*


    
*/
 