import "./main.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import playicons from "./playicon.svg";
import pauseicon from "./pause.svg";
import fullscreenicon from "./fullscreenIcon.svg";
import Loader from "../../utilities/Loader";
import configData from "../../vars.json";
import Button1 from "../../shared/Buttons/Button1";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { updateSampleSection } from "../sample-base";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { MyContext } from "../../utilities/ContextData";
const baseURL = configData.baseUrl ;

const Main = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [resultado, setResultado] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSign, setIsSign]=useState(true)
  const { main, setMain, setMyHome } = useContext(MyContext);
  const videoRef = useRef(null);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };


  const getdoc = async() =>{
     console.log("PRE")
     
     console.log(localStorage.getItem('headers'))
     console.log(JSON.parse(localStorage.getItem('headers')).id)
     console.log(JSON.parse(localStorage.getItem('headers')).rid)
     var aux=0
     await axios.get(baseURL+`wishes/pdf?id=`+JSON.parse(localStorage.getItem('headers')).rid, {  headers: {
      "content-type": 'text/json', 
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem('headers')).id
   
    }})
     .then(res => {
      console.log("RTA CH:")
      console.log(res.data)
       aux=res.data;
     })

     await axios.post(baseURL+"pdf/signer/envelope", {requestId:aux.url, wishId: JSON.parse(localStorage.getItem('headers')).rid,
     typeSelected: JSON.parse(localStorage.getItem('headers')).typeSelected,
     countrySelected: JSON.parse(localStorage.getItem('headers')).countrySelected,
     ver_id: JSON.parse(localStorage.getItem('headers')).ver_id,
     status: JSON.parse(localStorage.getItem('headers')).status,
     workflow_id: JSON.parse(localStorage.getItem('headers')).workflow_id,
     applicant_id: JSON.parse(localStorage.getItem('headers')).applicant_id
    },{  headers: {
      "content-type": 'text/json', 
      "Authorization": "Bearer "+JSON.parse(localStorage.getItem('headers')).id
   
    }}).then((response) => {
      
     console.log(localStorage.getItem('headers'))
     console.log("NUEVA RESPUESTA URL") 
     localStorage.setItem('signedpdf', JSON.stringify({envelope:response.data.envelope}))
     console.log(response.data.url)
     window.location.replace(response.data.url);
  
 }
  )



  }
  


  useEffect(async () => {
    setMyHome(false);
    getdoc();
    setTimeout(() => {
      setIsLoading(false);
    }, 50);


   
    
   
    
  }, []);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();



  const [numPages, setNumPages] = React.useState(null);
  const headers = {
    Authorization: "Hola Mundo",
  };

  const location = useLocation();
  const navigate = useNavigate();

  if (isLoading) {
    return <Loader />;
  }else{
  return (
    <section className="grid grid-cols-1 bg-white">
     {isSign && <Loader />}
     {!isSign &&<div className="pdfCont">
          <PdfViewerComponent
            id="container"
            documentPath={resultado}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
            style={{ height: "80vh" }}
          >
            <Inject
              services={[
                Toolbar,
                Magnification,
                Navigation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                Print,
                TextSelection,
                TextSearch,
                Annotation,
                FormFields,
                FormDesigner,
              ]}
            />
          </PdfViewerComponent>
        
        </div>}
    </section>
  );}
};

export default Main;
