import React from "react";
import Button1 from "../../shared/Buttons/Button1";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import configData from "./vars.json";
import axios from "axios";

const baseURLPromo = configData.BASE_PORTAL_URL + "promo";
export default function Navbar(props) {

 
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const handleclick = () => {

    const headers = {
      Authorization: "Hola Mundo",
    };
    console.log("Clicked handle click")
    console.log(headers.Authorization)
    console.log(query.get("id"));
    var reqpar=""
   
    if(props.id != ""){
      reqpar=props.id
    }else{
      reqpar=query.get("id")
    }
    console.log("Req Param: ")
    console.log(reqpar)
    axios
    .post(
      baseURLPromo,
      { id: reqpar },
      {
        headers: headers,
      }
    ).then((response) => {
      console.log(response);
    });

    toast("Característica en desarrollo", {
      icon: "⚙",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });

  };
  const location = useLocation();
  console.log(location.pathname);
  return (
    <div className="flex justify-between items-center border-b pb-[18px] border-[#EBEAED] px-3 lg:px-0">
      <div>
        <img
          src="assets/img/logo.png"
          alt=""
          className="max-w-[120px] sm:max-w-[150px] md:max-w-[196px]"
        />
      </div>
      {location.pathname !== "/expired" ? (
      //  <Button1 handleclick={handleclick}>Crear Cuenta</Button1>
      ""
      ) : (
        ""
      )}
    </div>
  );
};


